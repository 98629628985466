import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  Typography,
} from '@mui/material';
import styled from 'styled-components';

// Styled dialog for consistent appearance
const StyledDialog = styled(Dialog)`
  && {
    display: block;
    max-width: 600px;
    margin: 0 auto;
  }
`;

// Interface for modal props
interface PDFNotesModalProps {
  open: boolean;
  handleClose: () => void;
  leadId: string;
  currentNotes: string;
  onNotesUpdate: (newNotes: string) => void;
  generatePDF: (notes: string) => void;
}

// PDFNoteModal component definition
const PDFNoteModal: React.FC<PDFNotesModalProps> = ({
  open,
  handleClose,
  leadId,
  currentNotes,
  onNotesUpdate,
  generatePDF,
}) => {
  // State for managing notes, loading state, and character count
  const [notes, setNotes] = useState(currentNotes);
  const [loading, setLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);

  // Character limit for notes
  const charLimit = 440;

  // Update character count whenever notes change
  useEffect(() => {
    setCharCount(notes.length);
  }, [notes]);

  // Handle PDF generation and note updates
  const handleSave = async () => {
    try {
      setLoading(true);
      onNotesUpdate(notes);
      await generatePDF(notes);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  // Render modal with character counter and warning
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>Edit PDF Notes</DialogTitle>
      <DialogContent style={{ paddingTop: '10px' }}>
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          label="PDF Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          style={{ width: '600px', maxWidth: '100%' }}
        />
        <Typography
          variant="body2"
          color={charCount > charLimit ? 'error' : 'textSecondary'}
          style={{ marginTop: '4px', fontSize: '12px' }}
        >
          {charCount}/{charLimit}
        </Typography>
        {charCount > charLimit && (
          <Typography variant="caption" color="error">
            Character limit exceeded. Please shorten your notes.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} />}
        <Button onClick={handleClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={loading || charCount > charLimit}>
          {loading ? 'Generating...' : 'Generate PDF'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default PDFNoteModal;
