import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, CircularProgress, Paper, Button, Tooltip } from '@mui/material';
import { API_PREFIX } from '../../constants/urls';
import dayjs from 'dayjs'; // Import dayjs for date formatting
import styled from 'styled-components';

const StyledTypography = styled(Typography)`
  @media all and (max-width: 349px){
    display: none;
  }
`;

const AnalyticsMinimal: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showAnalytics, setShowAnalytics] = useState<boolean>(() => {
    // Initialize state from localStorage
    const storedShowAnalytics = localStorage.getItem('showAnalytics');
    return storedShowAnalytics !== null ? JSON.parse(storedShowAnalytics) : false;
  });

  // Helper function to format date range with month if needed
  const formatRange = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
    if (start.month() === end.month()) {
      return `${start.format('D')} - ${end.format('D MMM')}`;
    } else {
      return `${start.format('D MMM')} - ${end.format('D MMM')}`;
    }
  };

  // Calculate the date range for the past 7 days, excluding today
  const today = dayjs();
  const startDate = today.subtract(7, 'day');
  const endDate = today.subtract(1, 'day');

  const startCompareDate = today.subtract(14, 'day');
  const endCompareDate = today.subtract(8, 'day');

  const dateRange = formatRange(startDate, endDate);
  const compareRange = formatRange(startCompareDate, endCompareDate);

  // Function to fetch 7-day analytics data
  const fetchAnalytics = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token'); // Get the auth token from localStorage
      const response = await axios.get(`${API_PREFIX}/analytics/7Days`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      setAnalyticsData(response.data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setError('Failed to fetch analytics data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch analytics data when the component mounts and when showAnalytics changes
  useEffect(() => {
    if (showAnalytics) {
      fetchAnalytics();
    }
  }, [showAnalytics]);

  // Update localStorage when showAnalytics state changes
  useEffect(() => {
    localStorage.setItem('showAnalytics', JSON.stringify(showAnalytics));
  }, [showAnalytics]);

  // Calculate percentage change between current and previous stats
  const calculatePercentageChange = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  };

  // Format the percentage change with appropriate color
  const formatPercentageChange = (change: number) => {
    const formattedChange = change.toFixed(1);
    return (
      <Typography
        variant="body2"
        style={{
          color: change > 0 ? '#B9E123' : change < 0 ? '#B92929' : '#A1A1A1',
          fontSize: '12px',
          fontWeight: 'bold',
          lineHeight: 1,
          margin: '0 0 2px',
        }}
      >
        {change > 0 ? `+${formattedChange}%` : `${formattedChange}%`}
      </Typography>
    );
  };

  const renderStatBox = (title: string, current: number, previous: number) => {
    const percentageChange = calculatePercentageChange(current, previous);
    return (
      <Paper
        sx={{
          border: '1px solid rgba(0,0,0,0.22)',
          borderRadius: '12px',
          padding: '4px',
          textAlign: 'center',
          boxShadow: 'none',
          flex: '1',
          width: '100%',
        }}
      >
        <Typography variant="body1" color="textSecondary" sx={{ fontSize: '12px', lineHeight: 1, margin: 0 }}>
          {title}
        </Typography>
        <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold', lineHeight: 1, margin: '6px 0 0' }}>
          {current}
        </Typography>
        {formatPercentageChange(percentageChange)}
        <Typography variant="body2" color="textSecondary" sx={{ fontSize: '12px', lineHeight: 1, margin: '0 0 4px' }}>
          (Prev: {previous})
        </Typography>
      </Paper>
    );
  };

  return (
    <Box sx={{ padding: '0px', width: ['100%', '395px'], margin: '0 0 0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'end', textAlign: 'center', margin: ['0','4px 6px 0'], alignItems: 'center' }}>
        {showAnalytics && (
          <>
            <StyledTypography sx={{ fontSize: '12px', marginTop: '2px', marginRight: ['2px', '8px'], color: 'rgba(0,0,0,0.6)' }}>
              {dateRange} ({compareRange})
            </StyledTypography>
            <Button href="/analytics" sx={{ fontSize: '12px', textTransform: 'none', padding: '4px 4px 2px ' }}>
              Detailed View
            </Button>
          </>
        )}
        <Button onClick={() => setShowAnalytics(!showAnalytics)} sx={{ fontSize: '12px', textTransform: 'none', padding: '4px 4px 2px ' }}>
          {showAnalytics ? 'Hide 7 Day Analytics' : 'Show 7 Day Analytics'}
        </Button>
      </Box>
      {showAnalytics && (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="76px">
              <CircularProgress size={24} />
            </Box>
          ) : error ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="76px">
              <Typography typography="body1" color="error">
                {error}
              </Typography>
              </Box>
          ) : analyticsData ? (
            <Box width="100%">
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection="row"
                justifyContent="space-between"
                gap={['4px', '10px']}
              >
                {renderStatBox(
                  'Leads',
                  analyticsData.current?.numberOfLeads || 0,
                  analyticsData.previous?.numberOfLeads || 0,
                )}
                {renderStatBox(
                  'Photos',
                  analyticsData.current?.leadsWithPhotos || 0,
                  analyticsData.previous?.leadsWithPhotos || 0,
                )}
                {renderStatBox(
                  'Appointments',
                  analyticsData.current?.appointmentsScheduled || 0,
                  analyticsData.previous?.appointmentsScheduled || 0,
                )}
                {renderStatBox(
                  'Purchases',
                  analyticsData.current?.vehiclesPurchased || 0,
                  analyticsData.previous?.vehiclesPurchased || 0,
                )}
              </Box>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" height="76px">
              <Typography>No analytics data available.</Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default AnalyticsMinimal;
