import { baseColors } from './colors';

const components = {
    MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 'bold',
            '&:hover': {
              color: baseColors.secondary.main,  // Set your desired hover color here
              background: 'rgba(0,0,0,0.1)'
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: 'white',
            '&:hover': {
              background: 'rgba(0,0,0,0.1)'
            },
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            // Add this to update padding for mobile screens
            '@media (max-width: 899px)': {
              paddingLeft: '15px',  // or whatever value you want
              paddingRight: '15px',
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
              minHeight: '2rem',
              padding: '5px 15px',
          }
        }
      },
      /*
      MuiPaper: {
        styleOverrides: {
          root: {
            background: '#282828',
            color: 'white'
          }
        } 
      } */
    // ... other component settings
  };

  export default components;