import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, Typography, Badge, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import axios from 'axios';
import { API_PREFIX } from '../../constants/urls';
import { StyledDashboardButton } from '../theme/DashboardStyles';
import { formatTime } from '../../utils/formatDateTimeUtils';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface MessageNotification {
  id: string;
  text: string;
  sender: string;
  postedAt: number;
  notifiedUsers: string[];
}

interface MessagesProps {
  onMessageClick: (messageId: string) => void;
  userId: string | undefined;
  refreshTrigger: number;
}

const SMSNotifications: React.FC<MessagesProps> = ({ userId, onMessageClick, refreshTrigger }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [messages, setMessages] = useState<MessageNotification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_PREFIX}/sms-notifications`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setMessages(response.data);

        const unreadMessages = response.data.filter(
          (m: MessageNotification) => m.notifiedUsers && userId && !m.notifiedUsers.includes(userId)
        ).length;

        setUnreadCount(unreadMessages);
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    fetchMessages();
  }, [userId, refreshTrigger]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMessageClick = async (messageId: string, sender: string) => {
    onMessageClick(sender);
    handleMenuClose();

    try {
      const token = localStorage.getItem('token');
        await axios.post(
          `${API_PREFIX}/sms-notifications/mark-as-seen`,
          { messageId, userId },
          { headers: { Authorization: `Bearer ${token}` } }
        );

      setMessages(prevMessages =>
        prevMessages.map(m =>
          m.id === messageId
            ? { ...m, notifiedUsers: [...(m.notifiedUsers || []), userId!] }
            : m
        )
      );

      setUnreadCount(prevCount => prevCount - 1);
    } catch (error) {
      console.error('Failed to mark message as seen:', error);
    }
  };

  const handleClearAllMessages = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_PREFIX}/sms-notifications/clear-all`, { userId },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setMessages(prevMessages =>
        prevMessages.map((m) => ({
          ...m,
          notifiedUsers: [...(m.notifiedUsers || []), userId!],
        }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.error('Failed to clear messages:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <StyledDashboardButton color="inherit" onClick={handleMenuOpen} style={{ height: '58px', marginLeft: 'auto' }}>
        {unreadCount > 0 ? (
          <Badge
            badgeContent={unreadCount}
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#B9E123', 
                color: 'white', 
                fontSize: '0.65rem', 
                lineHeight: '17px',
                fontWeight: 'bold',
                height: '16px', 
                minWidth: '16px', 
                width: '16px'
              },
            }}
          >
            <EmailIcon style={{ fontSize: '22px' }} />
          </Badge> 
        ) : (
          <EmailIcon style={{ fontSize: '22px' }} />
        )}
      </StyledDashboardButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          margin: '5px 0 0',
          maxHeight: 480,
          width: ['340px', '380px'],
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {messages.length === 0 ? (
          <MenuItem disabled>No new messages</MenuItem>
        ) : (
          <Box sx={{ position: 'relative', width: ['300px', '350px'] }}>
            <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: '#FFF', textAlign: 'right', padding: '2px 0 10px 0', borderBottom: '1px solid #E9E9E9' }}>
              <Button
                  variant="outlined"
                  sx={{
                    fontSize: '12px',
                    color: '#888',
                    border: '1px solid #E1E1E1',
                    backgroundColor: '#F6F6F6',
                    width: '160px',
                    height: '20px',
                    margin: '0 10px 0 2px'
                  }}
                  href="/sms-history"
                >
                  View SMS History <KeyboardArrowRightIcon style={{ width: '14px', marginLeft: '4px' }} />
              </Button>
            </Box>
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
              {messages.map(message => (
                <MenuItem
                  key={message.id}
                  onClick={() => handleMessageClick(message.id, message.sender)}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 8px 2px 12px',
                    backgroundColor:
                      userId &&
                      message.notifiedUsers &&
                      !message.notifiedUsers.includes(userId)
                        ? '#F6F6F6'
                        : '#FFF',
                    borderBottom: '1px solid #E9E9E9',
                  }}
                >
                  <Box sx={{ flex: 1, minWidth: 0, marginRight: '8px' }}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '14px',
                        fontWeight:
                          userId &&
                          message.notifiedUsers &&
                          !message.notifiedUsers.includes(userId)
                            ? 'bold'
                            : 'normal',
                        lineHeight: '1.6',
                        whiteSpace: 'normal',
                        wordBreak: 'break-word',
                      }}
                    >
                      {message.text}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '12px', margin: '2px 0'}}>
                      Sent {formatTime(new Date(message.postedAt))} {message.sender}
                    </Typography>

                  </Box>
                </MenuItem>
              ))}
            </Box>
            <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: '#FFF', textAlign: 'center', padding: '10px 0 4px 0', borderTop: '1px solid #E9E9E9' }}>
              <Button
                variant="outlined"
                onClick={handleClearAllMessages}
                sx={{
                  fontSize: '12px',
                  color: '#888',
                  border: '1px solid #E1E1E1',
                  backgroundColor: '#F6F6F6',
                  width: '120px',
                  height: '20px',
                }}
              >
                Clear All
              </Button>
            </Box>
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default SMSNotifications;
