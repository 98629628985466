import React, { useState } from 'react';
import { Select, MenuItem, FormControl, Typography, Box, SelectChangeEvent } from '@mui/material';
import { emailTemplates, smsTemplates } from '../../constants/contactTemplates';
import { User } from '../../context/AuthContext';
import axios from 'axios';

interface TemplateSelectorProps {
  user: User | null;
  lead: any;
  fetchNotes: () => void;
  onSmsTemplateSelect: (template: string) => void; // New callback for SMS template selection
}

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

const TemplateSelector: React.FC<TemplateSelectorProps> = ({ user, lead, fetchNotes, onSmsTemplateSelect }) => {
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string>('');
  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState<string>('');

  const emailTemplatesList = emailTemplates(
    lead.personalInfo.firstName,
    lead.appointmentDate,
    lead.appointmentTime,
    lead.make,
    lead.model,
    user?.fullName,
    lead.eid
  );

  const smsTemplatesList = smsTemplates(
    lead.personalInfo.firstName,
    lead.appointmentDate,
    lead.appointmentTime,
    lead.make,
    lead.model,
    user?.fullName,
    lead.eid 
  );

  const handleEmailChange = async (event: SelectChangeEvent<string>) => {
    const templateLabel = event.target.value;
    
    // Check for "Follow Up - Appointment Reminder" and missing appointment time
    if ((templateLabel === 'Appointment - Confirmation (In Office)' || templateLabel === 'Appointment - Out of Office') && (!lead.appointmentTime || !lead.appointmentDate)) {
      alert('Appointment time not set');
      return;
    }

    setSelectedEmailTemplate(templateLabel);

    const selectedTemplate = emailTemplatesList.find(template => template.label === templateLabel);
    if (selectedTemplate) {
      const mailtoLink = `mailto:${lead.personalInfo.email}?subject=${encodeURIComponent(selectedTemplate.subject)}&body=${encodeURIComponent(selectedTemplate.body)}`;
      window.location.href = mailtoLink;

      try {
        const response = await axios.post(`${API_PREFIX}/add-note`, {
          leadId: lead.id,
          text: `${templateLabel} email sent`,
          noteType: 'Email',
          sender: user?.name || 'Unknown',
        }, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.status === 201) {
          fetchNotes();
        }
      } catch (error) {
        console.error('Failed to add note', error);
      }
    }
  };

  const handleSmsChange = (event: SelectChangeEvent<string>) => {
    const templateLabel = event.target.value;
    
    // Check for "Follow Up - Appointment Reminder" and missing appointment time
    if (templateLabel === 'Appointment - Confirmation' && !lead.appointmentTime) {
      alert('Appointment time not set');
      return;
    }

    setSelectedSmsTemplate(templateLabel);

    const selectedTemplate = smsTemplatesList.find(template => template.label === templateLabel);
    if (selectedTemplate) {
      onSmsTemplateSelect(selectedTemplate.body); // Pass template body to SMSSection
    }
  };

  return (
    <Box display="flex" flexDirection="column" margin="normal" width="100%" mt={1}>
      <Typography variant="subtitle1" sx={{ fontSize: '12px', color: '#777' }}>
        Select contact template:
      </Typography>
      <FormControl fullWidth>
        <Select
          onChange={handleEmailChange}
          displayEmpty
          value={selectedEmailTemplate}
          sx={{ height: '40px', fontSize: '16px', lineHeight: '32px', borderRadius: '10px' }}
        >
          <MenuItem value=""><em>Choose email template</em></MenuItem>
          {emailTemplatesList.map(template => (
            <MenuItem key={template.label} value={template.label}>{template.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mt: 1 }}>
        <Select
          onChange={handleSmsChange}
          displayEmpty
          value={selectedSmsTemplate}
          sx={{ height: '40px', fontSize: '16px', lineHeight: '32px', borderRadius: '10px' }}
        >
          <MenuItem value=""><em>Choose SMS template</em></MenuItem>
          {smsTemplatesList.map(template => (
            <MenuItem key={template.label} value={template.label}>{template.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TemplateSelector;
