import { Box, Paper, Typography } from '@mui/material';
import styled from 'styled-components';


export const LeadContainer = styled(Paper)`
  position: relative;
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 12px;
`
export const LeadContainerOuter = styled(Box)`
  display: flex;
  gap: 12px;
  @media all and (max-width: 1199px){
    flex-direction: column;
  }
`
export const LeadContainerMiddle = styled(Box)`
  display: flex;
  flex-direction: column;
  width: Calc(100% - 284px);
  @media all and (max-width: 1199px){
    width: 100%;
  }
`
export const LeadContainerHeading = styled(Box)`
  display: flex;
  justify-content: space-between;
  max-width: 570px;
  margin: 2px 0 8px;
  @media all and (max-width: 1199px){
    max-width: 100%;
  }
`
export const LeadHeadingText = styled(Typography)`
&&{
  font-weight: 700;
  width: Calc(100% - 30px);
}`
export const LeadContainerInner = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  @media all and (max-width: 1199px){
    flex-direction: column;
    gap: 0;
  }
`
export const LeadContainerSubNote = styled(Box)`
  margin: 4px 0 -4px;
`
export const VehicleContainer = styled(Box)`
  display: block;
  width: 280px;
  @media all and (max-width: 1199px){
    width: 100%;
  }
`
export const CustomerContainer = styled(Box)`
  display: block;
  width: 280px;
  @media all and (max-width: 1199px){
    width: 100%;
  }
`
export const NotesContainer = styled(Box)`
  display: block;
  width: 288px;
  margin: -35px 0 0;
  @media all and (max-width: 1199px){
    width: 100%;
    margin: 10px 0 10px;
  }
`
export const Stat = styled.div`
  display: flex;
  line-height: 18px;
  min-height: 18px;
  padding: 2px 0;
  align-items: center;
  margin-bottom: 8px;
`
export const DualStat = styled(Box)`
  display: flex;
  height: 27px;
  ${Stat}{
    width: 50%;
  }
  max-width: 260px;
`
export const FurtherInfoLabel = styled.label`
  display: block;
  position: absolute;
  background: white;
  margin: -15px 0 0;
  font-size: 12px;
  padding: 0 7px;
  color: #777;
`
export const FurtherInfoContainer = styled.div`
  margin: 10px 0 4px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 8px;
`
export const AppointmentContainer = styled.div`
  margin: 10px 0 4px;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  padding: 8px;
`
export const MinorStat = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 2px 6px 0 0;
  margin-left: 5px;
  margin-bottom: 0px;
`
export const MinorStats = styled.div`
  display: flex;
  ${MinorStat}:first-child {
    margin-left: 0; 
  }
  ${MinorStat}:last-child {
    border-right: none; 
  }
`
export const MinorStatText = styled(Typography)`
  &&{
    font-size: 13px;
  }
`
export const ConditionStat = styled(Box)`
  display: flex;
  margin-top: 8px;
`
export const AddressStat = styled(Box)`
  margin-top: 8px;
  font-size: 13px;
  lineHeight: 18px;
  color: #777;
`