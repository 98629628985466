import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Tabs, Tab, CircularProgress, Paper } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { API_PREFIX } from '../../constants/urls';
import dayjs from 'dayjs';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const AnalyticsOverview: React.FC = () => {
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7Days');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [dateRange, setDateRange] = useState<string>('');
  const [compareRange, setCompareRange] = useState<string>('');

  // Function to fetch analytics data
  const fetchAnalytics = async (period: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token'); // Get the auth token from localStorage
      const endpoint = period === 'last24Hours' ? '24hours' : period; // Adjust the endpoint for the 24-hour case
      const response = await axios.get(`${API_PREFIX}/analytics/${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      setAnalyticsData(response.data);
    } catch (error) {
      console.error('Error fetching analytics:', error);
      setError('Failed to fetch analytics data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch analytics data when the component mounts or the selected period changes
  useEffect(() => {
    fetchAnalytics(selectedPeriod);
  }, [selectedPeriod]);

  // Handle period change using Tabs
  const handlePeriodChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedPeriod(newValue);
  };

  // Calculate percentage change between current and previous stats
  const calculatePercentageChange = (current: number, previous: number) => {
    if (previous === 0) return current > 0 ? 1000 : 0;
    return ((current - previous) / previous) * 100;
  };

  // Format the percentage change with appropriate color
  const formatPercentageChange = (change: number) => {
    const formattedChange = change.toFixed(1);
    return (
      <Typography
        variant="body2"
        style={{
          color: change > 0 ? '#B9E123' : change < 0 ? '#B92929' : '#A1A1A1',
          fontWeight: 'bold',
        }}
      >
        {change > 0 ? `+${formattedChange}%` : `${formattedChange}%`}
      </Typography>
    );
  };

  // Calculate the date range being shown
  useEffect(() => {
    const today = dayjs();
    let startDate, endDate, startCompareDate, endCompareDate;
  
    const formatRange = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
      if (start.month() === end.month()) {
        return `${start.format('D')} - ${end.format('D MMM')}`;
      } else {
        return `${start.format('D MMM')} - ${end.format('D MMM')}`;
      }
    };
  
    switch (selectedPeriod) {
      case 'last24Hours':
        // Handle last 24 hours case, here we don't need a date range
        setDateRange('');
        setCompareRange('');
        break;
      case '7Days':
        startDate = today.subtract(7, 'day');
        endDate = today.subtract(1, 'day');
        startCompareDate = today.subtract(14, 'day');
        endCompareDate = today.subtract(8, 'day');
        setDateRange(formatRange(startDate, endDate));
        setCompareRange(formatRange(startCompareDate, endCompareDate));
        break;
      case '14Days':
        startDate = today.subtract(14, 'day');
        endDate = today.subtract(1, 'day');
        startCompareDate = today.subtract(28, 'day');
        endCompareDate = today.subtract(15, 'day');
        setDateRange(formatRange(startDate, endDate));
        setCompareRange(formatRange(startCompareDate, endCompareDate));
        break;
      case '30Days':
        startDate = today.subtract(30, 'day');
        endDate = today.subtract(1, 'day');
        startCompareDate = today.subtract(60, 'day');
        endCompareDate = today.subtract(31, 'day');
        setDateRange(formatRange(startDate, endDate));
        setCompareRange(formatRange(startCompareDate, endCompareDate));
        break;
      case '90Days':
        startDate = today.subtract(90, 'day');
        endDate = today.subtract(1, 'day');
        startCompareDate = today.subtract(180, 'day');
        endCompareDate = today.subtract(91, 'day');
        setDateRange(formatRange(startDate, endDate));
        setCompareRange(formatRange(startCompareDate, endCompareDate));
        break;
      default:
        setDateRange('');
        setCompareRange('');
        break;
    }
  }, [selectedPeriod]);
  

  const renderStatBox = (title: string, current: number, previous: number) => {
    const percentageChange = calculatePercentageChange(current, previous);
    return (
      <Paper
        sx={{
          border: '1px solid rgba(0,0,0,0.22)',
          borderRadius: '12px',
          padding: '16px',
          flex: '1 1 calc(25% - 16px)',
          minWidth: '200px',
          margin: '8px',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          '@media (max-width: 599px)': {
            flex: '1 1 100%', // Full width on mobile screens
          },
        }}
      >
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h5" sx={{ marginTop: '8px', fontWeight: 'bold' }}>
          {current}
        </Typography>
        {formatPercentageChange(percentageChange)}
        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '8px' }}>
          Previous: {previous}
        </Typography>
      </Paper>
    );
  };

  const renderBarChart = () => {
    if (!analyticsData || !analyticsData.current || !analyticsData.previous) return 'No source data located.';
  
    const currentSourceCounts = analyticsData.current.sourceCounts || {};
    const previousSourceCounts = analyticsData.previous.sourceCounts || {};
  
    // Create an array of sources with their counts for sorting
    const sources = Object.keys(currentSourceCounts).map(label => ({
      label,
      current: currentSourceCounts[label] || 0,
      previous: previousSourceCounts[label] || 0,
    }));
  
    // Sort sources by current period lead counts in descending order
    sources.sort((a, b) => b.current - a.current);
  
    // Extract sorted labels and data
    const labels = sources.map(source => source.label);
    const currentData = sources.map(source => source.current);
    const previousData = sources.map(source => source.previous);
  
    // Color mapping for specific sources
    const colorMap: { [key: string]: string } = {
      
      'Direct': 'rgba(165, 225, 15, 0.8)', // CBO green
      'Google Ads': 'rgba(66, 133, 244, 0.8)', // Blue
      'Google (organic)': 'rgba(255, 26, 36, 0.8)', // Red
      'Facebook Ads': 'rgba(66,103,178, 0.8)', // Facebook blue
      'Instagram Ads': 'rgba(255, 48, 108, 0.8)', // Instagram pink
      'Other': 'rgba(100, 100, 100, 0.8)', // Light Gray
      'unknown': 'rgba(30, 30, 30, 0.8)', // Gray
    };
  
    // Default color for unspecified sources
    const defaultColor = 'rgba(30, 30, 30, 0.6)'; // Gray
  
    // Assign colors based on the source mapping
    const currentColors = labels.map(label => colorMap[label] || defaultColor);
    const previousColors = labels.map(label => colorMap[label]?.replace('0.8', '0.4') || defaultColor.replace('0.8', '0.4')); // Slightly faded for previous period
  
    const data = {
      labels,
      datasets: [
        {
          label: 'Current Period',
          data: currentData,
          backgroundColor: currentColors,
        },
        {
          label: 'Previous Period',
          data: previousData,
          backgroundColor: previousColors,
        },
      ],
    };
  
    const options = {
      responsive: true,
      indexAxis: 'y' as const, // Flip the axes to horizontal
      plugins: {
        legend: {
          position: 'top' as const,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => `${context.dataset.label}: ${context.raw}`,
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Leads',
          },
          beginAtZero: true,
        },
        y: {
          title: {
            display: true,
            text: 'Traffic Sources',
          },
        },
      },
    };
  
    return (
      <Paper
        sx={{
          border: '1px solid rgba(0,0,0,0.22)',
          borderRadius: '12px',
          padding: '16px',
          margin: '8px',
          textAlign: 'center',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          overflowX: 'auto'
        }}
      >
        <Typography variant="body2" color="textSecondary" style={{ textAlign: 'center', marginBottom: '20px' }}>
          Leads by Source
        </Typography>
        <Box width="100%" minWidth="460px">
          <Bar data={data} options={options}/>
        </Box>
      </Paper>
    );
  };
  
  

  return (
    <Box sx={{ padding: '16px' }}>
      <Tabs
        value={selectedPeriod}
        onChange={handlePeriodChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable" // Makes tabs scrollable on small screens
        scrollButtons="auto" // Adds scroll buttons for tab navigation
        sx={{ marginBottom: 2 }}
      >
        <Tab value="last24Hours" label="Last 24 Hours" sx={{ fontSize: 16 }} />
        <Tab value="7Days" label="Last 7 Days" />
        <Tab value="14Days" label="Last 14 Days" />
        <Tab value="30Days" label="Last 30 Days" />
        <Tab value="90Days" label="Last 90 Days" />
      </Tabs>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
        <Typography sx={{ fontSize: '14px', color: 'rgba(0,0,0,0.6)', margin: '0 0 0 12px' }}>
        {selectedPeriod === 'last24Hours' ? (
          `Last 24 hours (updates once per 15 min)`
          ) : (
            dateRange && `${dateRange} (Compared to: ${compareRange})`
          )}
        </Typography>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : analyticsData ? (
        <Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            {renderStatBox(
              'Number of Leads',
              analyticsData.current?.numberOfLeads || 0,
              analyticsData.previous?.numberOfLeads || 0
            )}
            {renderStatBox(
              'Leads with Photos',
              analyticsData.current?.leadsWithPhotos || 0,
              analyticsData.previous?.leadsWithPhotos || 0
            )}
            {renderStatBox(
              'Appointments Scheduled',
              analyticsData.current?.appointmentsScheduled || 0,
              analyticsData.previous?.appointmentsScheduled || 0
            )}
            {renderStatBox(
              'Vehicles Purchased',
              analyticsData.current?.vehiclesPurchased || 0,
              analyticsData.previous?.vehiclesPurchased || 0
            )}
          </Box>
          <Box>
          {renderBarChart()}
        </Box>
        </Box>
      ) : (
        <Typography>No analytics data available.</Typography>
      )}
    </Box>
  );
};

export default AnalyticsOverview;
