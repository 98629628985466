import React, { useEffect, useRef, useState } from 'react';
import { Box, Tooltip, Typography, IconButton, Button, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import styled from 'styled-components';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

import { Lead } from '../../types/LeadTypes';
import ImageDisplay from './ImageDisplay';
import { formatDate, formatTime, reformatDate } from '../../utils/formatDateTimeUtils';

import { formatNumber } from '../../utils/numberUtils';

import TemplateSelector from './TemplateSelector';
import AssignLeadSelector from './AssignLeadSelector';

import { CustomerContainer, FurtherInfoLabel, FurtherInfoContainer, LeadContainer, LeadContainerHeading, LeadContainerInner, LeadContainerMiddle, LeadContainerOuter, LeadContainerSubNote, 
  MinorStat, MinorStatText, MinorStats, Stat, VehicleContainer, ConditionStat, DualStat, NotesContainer, AppointmentContainer, AddressStat,
  LeadHeadingText
  } from '../theme/LeadDisplayStyles';

import { ExpandableText } from '../theme/ExpandableText'
import ConditionSentiment from '../theme/ConditionSentimentIcon';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EngineIcon from '../../assets/icons/engine.svg';
import ShapeIcon from '../../assets/icons/shape.svg';
import TransmissionIcon from '../../assets/icons/transmission.svg';
import OdometerIcon from '../../assets/icons/odometer.svg';

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import RectangleIcon from '@mui/icons-material/Crop169';

import CalendarIcon from '@mui/icons-material/CalendarTodayOutlined';
import CalendarTickIcon from '@mui/icons-material/EventAvailableOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

import MoneyIcon from '@mui/icons-material/AttachMoney';
import CreditIcon from '@mui/icons-material/CreditCard';
import MessageIcon from '@mui/icons-material/Message';
import NotesSection from './NotesSection';
import NotesIconHeading from '../theme/NotesIconHeading';
import EditLead from './EditLead';
import PDFNoteModal from './PDFNoteModal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SMSSection from './SMSSection';

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin: -2px 5px 2px 0;
`;

export const EditButton = styled(IconButton)`
&&{
  display: block;
  font-size: 12px;
  color: rgb(178, 178, 178);
  line-height: 20px;
  height: 28px;
  padding: 6px 4px;
  font-weight: 600;
  border-radius: 8px;
}`

const StyledPDFButton = styled(Button)`
  display: block;
  width: 100%;
  height: 38px;
  @media all and (max-width: 1199px){
    width: 100%;
  }
`
const LeadInfo = styled.span`
  display: inline-block;
  padding: 0 4px;
  margin: 0 0 4px;
  border-left: 1px solid #CCC;
`

interface LeadDisplayProps {
  lead: Lead;
  hideSensitive: boolean;
  isMobile: boolean;
}

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

const LeadDisplay: React.FC<LeadDisplayProps> = ({ lead, hideSensitive, isMobile }) => {
  const { user } = useAuth();
  const [isBookmarked, setIsBookmarked] = useState(lead.bookmarkUsers?.includes(user?.name || 'No User') || false);
  const [activeSection, setActiveSection] = useState<"notes" | "sms">("notes");
  const [isNotesExpanded, setIsNotesExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentLead, setCurrentLead] = useState<Lead>(lead);
  const enquiryDate = new Date(currentLead.createdAt);
  const formattedDate = formatDate(enquiryDate);
  const formattedTime = formatTime(enquiryDate);
  const isRecent = formattedDate === "Today" || formattedDate === "Yesterday";
  const [smsTemplateText, setSmsTemplateText] = useState('');


  const handleSectionToggle = (section: 'notes' | 'sms') => {
    setActiveSection(section);
    if (section === 'notes') setIsNotesExpanded(true); // Auto-expand on Notes section toggle
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateLead = (updatedLead: Lead) => {
    setCurrentLead(updatedLead); // Update the state with the new lead data
    setIsEditing(false); // Close the edit form
  };

  // Format appointment date and time using utility functions
  const appointmentDate = currentLead.appointmentDate ? reformatDate(currentLead.appointmentDate) : null;
  const appointmentTime = currentLead.appointmentTime ? currentLead.appointmentTime : null;
  
  // State and function to trigger a refresh in NotesSection
  const [refreshCount, setRefreshCount] = useState(0);

  // Mobile responsiveness
  // State to manage compression
  const [isCarExpanded, setIsCarExpanded] = useState<boolean>(isMobile ? false : true);

  const leadContainerRef = useRef<HTMLDivElement>(null);
  
  const toggleCarExpanded = () => {
    setIsCarExpanded(isMobile && !isCarExpanded);
    if (isMobile && isCarExpanded && leadContainerRef.current) {
      if (leadContainerRef.current) {
        leadContainerRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        setTimeout(() => {
          window.scrollBy(0, -78);
        }, 10); // Delay 20ms to ensure smooth scroll completes before applying offset
      }
    }
  };

  // Handler to set SMS template text in SMS input box
  const handleSmsTemplateSelect = (template: string) => {
    setActiveSection('sms');
    setSmsTemplateText(template);
  };


  // Refresh Leads
  const triggerRefresh = () => {
    setRefreshCount((prevCount) => prevCount + 1);
  };

  // Refresh Notes
  const fetchNotes = async () => {
    try {
      const response = await axios.get(`${API_PREFIX}/notes/${currentLead.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setCurrentLead((prevLead) => ({
        ...prevLead,
        notes: response.data, // Update notes in the lead state
      }));
    } catch (error) {
      console.error('Failed to fetch notes:', error);
    }
  };

  // Postcode regions
  const [regions, setRegions] = useState<string[]>([]);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const fetchRegionsForPostcode = async (postcode: string) => {
    try {
      const response = await fetch(`${API_PREFIX}/regions/${postcode}`);
      if (!response.ok) {
        if (response.status === 404) {
          // If the backend returns a 404, it means no locations were found for the given postcode
          setRegions(["No locations found for the given postcode."]);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        const data = await response.json();
        // Check if regions array is empty and set a default message
        if (data.regions.length === 0) {
          setRegions(["No locations found for the given postcode."]);
        } else {
          setRegions(data.regions);
        }
      }
    } catch (error) {
      console.error('Error fetching regions:', error);
      // Optionally, set a generic error message if the request fails
      setRegions(["Error fetching regions. Please try again later."]);
    }
  };

  const handleMouseEnter = () => {
    if (!regions.length) {
      fetchRegionsForPostcode(currentLead.personalInfo.postcode);
    }
    setOpenTooltip(true);
  };

  const handleMouseLeave = () => {
    setOpenTooltip(false);
  };

  // Add to user's bookmarks
  const toggleBookmark = async () => {
    try {
      const updatedStatus = !isBookmarked;
      const response = await axios.post(`${API_PREFIX}/toggle-bookmark`, {
        leadId: currentLead.id,
        userName: user?.name,
        bookmarked: updatedStatus
      });
      setIsBookmarked(updatedStatus); // Update local state based on the response
      console.log(response.data.message);
    } catch (error) {
      console.error('Error toggling bookmark:', error);
    }
  };

  // Dealer notes modal - for PDF generation
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const handleNotesModalClose = () => {
    setNotesModalOpen(false);
  };

  const handleNotesUpdate = (newNotes: string) => {
    setCurrentLead((prevLead) => ({ ...prevLead, dealerNotes: newNotes }));
  };

  const handlePDFGeneration = async (notes: string) => {
    try {
      const response = await axios.post(`${API_PREFIX}/generate-pdf`, {
        customerData: currentLead,
        message: notes
      }, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob' // Necessary for file download
      },
    );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cbo-${currentLead.eid}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  if (isEditing) {
    return <EditLead lead={currentLead} onCancel={() => setIsEditing(false)} onUpdate={handleUpdateLead} />;
  }
  
  return (
    <LeadContainer key={currentLead.id} elevation={isMobile ? 4 : 3} ref={leadContainerRef}>
      <LeadContainerOuter>
        <ImageDisplay key={currentLead.id} leadId={currentLead.id} images={currentLead.images || []} isMobile={isMobile} />
        <LeadContainerMiddle>
          <LeadContainerHeading>
            <LeadHeadingText>{currentLead.year} {currentLead.make} {currentLead.model} {currentLead.badge}</LeadHeadingText>
            <IconButton onClick={toggleBookmark} style={{ top: '2px', width: '26px', height: '26px', borderRadius: '8px' }}>
              {isBookmarked ? <StarIcon style={{ fontSize: '20px', color: '#ACD513' }} /> : <StarBorderIcon style={{ fontSize: '20px', color: '#B2B2B2' }} />}
            </IconButton>
            <EditButton onClick={handleEditClick}>Edit</EditButton>
          </LeadContainerHeading>
          <LeadContainerInner>
            <VehicleContainer sx={{ marginBottom: { xs: isCarExpanded ? '20px' : 0, lg: 0 } }}>
              <Stat><Icon src={ShapeIcon} alt="Shape" /> {currentLead.shape}</Stat>
              <Stat><Icon src={EngineIcon} alt="Engine" /> {currentLead.engine}</Stat>
              {isCarExpanded && (
                <>
                  <Stat><Icon src={TransmissionIcon} alt="Transmission" /> {currentLead.transmission}</Stat>
                  <DualStat>
                    <Stat><Icon src={OdometerIcon} alt="Odometer" />{currentLead.kilometers ? (<>{formatNumber(currentLead.kilometers)}</>) : '-'}</Stat>
                    <Stat><RectangleIcon style={{ color: '#777', width: '15px', height: '16px', margin: '-1px 5px 1px 0'  }} />{currentLead.kilometers ? (<>{currentLead.rego}</>) : '-'}</Stat>
                  </DualStat>
                  <Stat>
                    <MoneyIcon style={{ width: '16px', color: '#444', margin: '-2px 5px 2px 0' }} />
                    {currentLead.estimatedPLow === '0' ? (
                      'Not provided'
                    ) : (
                      <>
                        {formatNumber(currentLead.estimatedPLow)} - {formatNumber(currentLead.estimatedPHigh)} 
                        {/* 
                          <span style={{ marginLeft: '4px', fontSize: '11px' }}>
                            {currentLead?.progress && Number(currentLead?.progress) < 3 && (<>(not seen)</>)}
                          </span>
                        */}
                      </>
                    )}
                  </Stat>
                  {(currentLead?.priceTarget || currentLead?.urgency || currentLead?.finance || currentLead?.condition) && (
                    <FurtherInfoContainer>
                      <FurtherInfoLabel>Further Info</FurtherInfoLabel>
                      <MinorStats style={{ color: '#777' }}>
                        {currentLead?.priceTarget && currentLead.priceTarget !== '' && (
                          <MinorStat>
                            <PersonIcon style={{ position: 'absolute', width: '10px', margin: '5px 0 -8px 10px' }} />
                            <MoneyIcon style={{ width: '16px', margin: '-2px 5px 2px 0' }} />
                            <MinorStatText>{formatNumber(currentLead.priceTarget)}</MinorStatText>
                          </MinorStat>
                        )}
                        {currentLead?.urgency && currentLead.urgency !== '' && (
                          <MinorStat>
                            <CalendarIcon style={{ width: '16px', margin: '-2px 4px 2px 0' }} /> 
                            <MinorStatText>{currentLead.urgency}</MinorStatText>
                          </MinorStat>
                        )}
                        {currentLead?.finance && currentLead.finance !== '' && currentLead.finance === 'Yes' && (
                          <MinorStat>
                            <CreditIcon style={{ width: '16px', margin: '-2px 5px 2px 0' }} />
                            <MinorStatText>Under Finance</MinorStatText>
                          </MinorStat>
                        )}
                      </MinorStats>
                      {currentLead?.condition && (
                        <ConditionStat>
                          <ConditionSentiment condition={currentLead.condition} />
                          <ExpandableText text={currentLead.condition} />
                        </ConditionStat>
                      )}
                    </FurtherInfoContainer>
                  )}
                </>
              )}
            </VehicleContainer>
            <CustomerContainer>
              <Stat style={{ fontWeight: isMobile ? 'bold' : 'regular' }}>
                <PersonIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} /> {currentLead.personalInfo.firstName} {currentLead.personalInfo.lastName}</Stat>
              {isCarExpanded && (
                <>
                    <Stat>
                      <EmailIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <a href={`mailto:${currentLead.personalInfo.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {currentLead.personalInfo.email}
                      </a>
                    </Stat>
                    <Stat>
                      <PhoneIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <a href={`tel:${currentLead.personalInfo.phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {currentLead.personalInfo.phone}
                      </a>
                    </Stat>
                    <Stat onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'default', maxWidth: '60px'}}>
                      <PlaceIcon style={{ width: '15px', height: '16px', margin: '-2px 5px 2px 0' }} />
                      <Tooltip
                        title={regions.join(', ')}
                        open={openTooltip}
                        placement="top"
                        disableHoverListener
                      >
                        <span>{currentLead.personalInfo.postcode}</span>
                      </Tooltip>
                    </Stat>
                  </>
                )}
                {appointmentDate && appointmentDate !== '' && (
                  <AppointmentContainer>
                    <FurtherInfoLabel>{currentLead.appointmentLocation === 'My Address' ? `OUT OF OFFICE Appointment` : `Appointment`}</FurtherInfoLabel>
                    <MinorStats style={{ color: '#777' }}>
                      <MinorStat>
                        <CalendarTickIcon style={{ width: '16px', margin: '-2px 4px 2px 0' }} /> 
                        <MinorStatText>{appointmentDate}</MinorStatText>
                      </MinorStat>
                      {appointmentTime && appointmentTime !== '' && (
                        <MinorStat>
                          <ScheduleOutlinedIcon style={{ width: '16px', margin: '-2px 4px 2px 0' }} /> 
                          <MinorStatText>{appointmentTime}</MinorStatText>
                        </MinorStat>
                      )}
                      {currentLead?.appointmentLocation && currentLead.appointmentLocation !== '' && (
                        <MinorStat>
                          <PlaceOutlinedIcon style={{ width: '16px', margin: '-2px 4px 2px 0' }} /> 
                          <MinorStatText>{currentLead.appointmentLocation === 'Car Buyers Online' ? 'CBO' : 'Address:'}</MinorStatText>
                        </MinorStat>
                      )}
                    </MinorStats>
                    {currentLead?.personalInfo?.address && (
                      <AddressStat>{currentLead?.personalInfo?.address}</AddressStat>
                    )}
                  </AppointmentContainer>
                )}
              {isCarExpanded && (
                <>
                  <TemplateSelector user={user} lead={currentLead} fetchNotes={fetchNotes} onSmsTemplateSelect={handleSmsTemplateSelect} />
                  <Box style={{ marginTop: '10px' }}>
                    <StyledPDFButton variant="outlined" onClick={() => setNotesModalOpen(true)}>
                      Generate PDF <KeyboardArrowRightIcon />
                    </StyledPDFButton>
                  </Box>
                </>
              )}
              
            </CustomerContainer>
            <NotesContainer>
              <NotesIconHeading
                user={user} 
                leadId={currentLead.id}
                activeSection={activeSection}
                onSectionToggle={handleSectionToggle}
                smsStatus={currentLead.smsStatus}
                phoneStatus={currentLead.phoneStatus} 
                messageStatus={currentLead.messageStatus} 
                hasPhotos={currentLead.images?.length > 0} 
                appointmentStatus={currentLead.appointmentStatus} 
                leadStatus={currentLead.leadStatus} 
                fetchNotes={fetchNotes}
                minimal={!isCarExpanded}
                // Email templates
                firstName={currentLead.personalInfo.firstName}
                year={currentLead.year}
                make={currentLead.make}
                model={currentLead.model}
                eid={currentLead.eid}
                toggleCarExpanded={toggleCarExpanded}
                />
                {isCarExpanded && (
                  (activeSection === "notes" ? (
                    <NotesSection 
                      user={user} 
                      leadId={currentLead.id} 
                      hideSensitive={hideSensitive}
                      notes={currentLead.notes || []}
                      fetchNotes={fetchNotes}
                      isNotesExpanded={isNotesExpanded}
                    />
                    ) : (
                    <SMSSection 
                      leadId={currentLead.uid}
                      to={currentLead.personalInfo.phone}
                      setMessage={smsTemplateText}
                    />
                  ))
                )}
            </NotesContainer>

            <IconButton onClick={toggleCarExpanded} sx={{ display: { xs: 'flex', lg: 'none' }, width: '100%', height: '40px', marginTop: isCarExpanded ? '12px' : '8px', marginBottom: isCarExpanded ? '8px' : '4px', color: '#999', border: '1px solid #E9E9E9', background: 'rgb(0,0,0,0.1)', borderRadius: '10px',  }}>
              <Typography style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.87)', }}>{isCarExpanded ? 'Hide lead details' : 'View all lead details'}</Typography> {isCarExpanded ? <ExpandMoreIcon style={{ fontSize: '20px', margin: '-1px 0 1px 6px', transform: 'rotate(180deg)', color: 'rgba(0, 0, 0, 0.54)' }} /> : <ExpandMoreIcon style={{ fontSize: '20px', margin: '-1px 0 1px 6px', color: 'rgba(0, 0, 0, 0.54)' }} />}
            </IconButton>
          </LeadContainerInner>
        </LeadContainerMiddle>
      </LeadContainerOuter>
      <LeadContainerSubNote>
        <Box fontSize="11px" style={{ display: 'block', lineHeight: '16px', fontSize: '11px' }}>
          <LeadInfo style={{ fontWeight: isRecent ? 'bold' : 'normal', borderLeft: '0 none' }}>
            {formattedDate}
          </LeadInfo>
          <LeadInfo>
            {formattedTime}
          </LeadInfo>
          <LeadInfo>
            ID: {currentLead.uid}
          </LeadInfo> 
          {(currentLead.siteURL && currentLead.siteURL != 'carbuyersonline.com.au') ? <LeadInfo>Site: <strong>{currentLead.siteURL}</strong></LeadInfo> : '' }
          {(currentLead.valuationEnquiry && currentLead.valuationEnquiry === true ) ? <LeadInfo>Type: <strong>Valuation Enquiry</strong></LeadInfo> : '' }
          {currentLead.source ? <LeadInfo>Source: {currentLead.source}</LeadInfo>: '' }
          {currentLead.referrer ? <LeadInfo style={{ display: 'none' }}>{currentLead.referrer}</LeadInfo>: '' }
          <LeadInfo> Assign to: <AssignLeadSelector leadId={lead.id} assignedTo={lead.assignedTo} /></LeadInfo>
        </Box>
      </LeadContainerSubNote>
      <PDFNoteModal
        open={notesModalOpen}
        handleClose={handleNotesModalClose}
        leadId={currentLead.id}
        currentNotes={currentLead.dealerNotes || ''}
        onNotesUpdate={handleNotesUpdate}
        generatePDF={handlePDFGeneration}
      />
    </LeadContainer>
  );
};

export default LeadDisplay;
