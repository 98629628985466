
// SEO Config (ADMIN PANEL)

const SITE_METADATA = {
    '/': {
        title: 'Car Buyers Online',
        description: 'Car Buyers Online will buy any make any model and we guarantee same day payment!* Sell your car online with Car Buyers Online today!',
    }
    // ... more routes
};

export default SITE_METADATA;