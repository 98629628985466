import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';
import { API_PREFIX } from '../constants/urls';

const RequestResetTokenPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleRequestToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_PREFIX}/generate-reset-token`, { email });
      setMessage(`Reset token generated successfully.`);
    } catch (error) {
      console.error('Error requesting reset token:', error);
      setMessage('Error requesting reset token');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh' }}
    >
      <form onSubmit={handleRequestToken}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px', marginBottom: '20px' }}>
            Request Reset Token
          </Button>
        </Box>
      </form>
      {message && <p>{message}</p>}
    </Box>
  );
};

export default RequestResetTokenPage;
