import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

export interface User {
  id: string;
  name: string;
  fullName: string;
  email: string;
}

interface AuthContextType {
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string, name: string) => Promise<void>;
  setupPassword: (email: string, password: string, resetToken: string) => Promise<void>;
  isAuthenticated: boolean;
  checkLoginState: () => void;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => useContext(AuthContext)!;

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));

  const login = async (email: string, password: string) => {
    console.log('Logging in...');
    const response = await axios.post<{ user: User; token: string }>(`${API_PREFIX}/login`, { email, password });
    localStorage.setItem('token', response.data.token);
    setUser(response.data.user);
    setIsAuthenticated(true);
    console.log('Login successful', { user: response.data.user });
  };

  const logout = () => {
    console.log('Logging out');
    localStorage.removeItem('token');
    setUser(null);
    setIsAuthenticated(false);
  };

  const register = async (email: string, password: string, name: string) => {
    console.log('Registering...');
    await axios.post(`${API_PREFIX}/register`, { email, password, name });
    console.log('Registration successful');
  };

  const setupPassword = async (email: string, password: string, resetToken: string) => {
    console.log('Setting password...');
    await axios.post(`${API_PREFIX}/set-password`, { email, password, resetToken });
    console.log('Password set successfully');
  };

  const checkLoginState = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await axios.post(`${API_PREFIX}/check-login`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data.valid && response.data.user) {
          setUser(response.data.user);
          setIsAuthenticated(true);
        } else {
          console.error('Token validation failed or no user data:', response.data);
          logout();
        }
      } catch (error) {
        console.error('Error checking login state:', error);
        logout();
      }
    }
  };

  useEffect(() => {
    checkLoginState();
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, register, setupPassword, isAuthenticated, checkLoginState }}>
      {children}
    </AuthContext.Provider>
  );
};
