import React, { useEffect, useState, ChangeEvent, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { Lead } from '../types/LeadTypes';
import LeadDisplay from '../components/backend/LeadDisplay';
import { Box, FormControl, InputLabel, List, ListItem, ListItemText, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import { API_PREFIX } from '../constants/urls';


import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { StyledDashboardButton } from '../components/theme/DashboardStyles';
import FilterOptions from '../components/backend/FilterOptions';
import { ContentWidth } from '../components/ThemeComponents';

import TuneIcon from '@mui/icons-material/Tune';
import RefreshIcon from '@mui/icons-material/Refresh';
// import VisibilityOnIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AnalyticsMinimal from '../components/theme/AnalyticsMinimal';
import Notifications from '../components/backend/Notifications';
import SMSNotifications from '../components/backend/SMSNotifications';
import { useAuth } from '../context/AuthContext';
import { red } from '@mui/material/colors';

const SearchFlex = styled(Box)`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  @media all and (max-width: 1199px){
    flex-direction: column;
  }
`;
const SortFlex = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const HeaderArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media all and (max-width: 599px){
    flex-direction: column;
  }
`
const StyledListItem = styled(ListItem)`
  display: block;
  padding: 2px 16px 2px;
  margin: 0;
  cursor: pointer;
  &:hover{
    background: rgba(0,0,0,0.08);
  }
`
const SearchReset = styled(Box)`
  position: absolute;
  top: 6px;
  padding: 12px 10px;
  height: 20px;
  line-height: 20px;
  font-weight: 300;
  background: white;
  border-radius: 8px;
  color: #B9E123;
  cursor: pointer;
  right: 430px;
  z-index: 10;
  border: 1px solid transparent;
  &:hover{
    background: rgba(185,225, 35, 0.06);
    border: 1px solid #B9E123;
  }
  @media all and (max-width: 1199px){
    right: 5px;
  } 
`

// Helper function to extract and decode the query string
const getQueryParam = (param: string): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param) ? decodeURIComponent(urlParams.get(param)!) : null;
};

const DashboardPage: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [leads, setLeads] = useState<Lead[]>([]);
  const suggestionsRef = useRef<HTMLDivElement | null>(null);
  const [suggestions, setSuggestions] = useState<{ make: string; model: string }[]>([]);
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const latestQueryRef = useRef<string>(''); // Ref to track the latest query
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('desc');
  const [filterChangeCount, setFilterChangeCount] = useState(0);
  const [filteredYear, setFilteredYear] = useState<string | undefined>('');
  const [filteredMake, setFilteredMake] = useState<string | undefined>('');
  const [filteredModel, setFilteredModel] = useState<string | undefined>('');
  const [filteredOtherStatus, setFilteredOtherStatus] = useState<string | undefined>('');
  const [filteredLeadStatus, setFilteredLeadStatus] = useState<string | undefined>('');
  const [filteredAssignedTo, setFilteredAssignedTo] = useState<string | undefined>('');
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(40);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastDocId, setLastDocId] = useState<string | null>(null);
  const [lastPageReached, setLastPageReached] = useState<boolean>(false);
  const [hideSensitive, setHideSensitive] = useState<boolean>(false); 
  const [showNoLeadsMessage, setShowNoLeadsMessage] = useState(false);

  const [showFilters, setShowFilters] = useState<boolean>(() => {
    const storedShowFilters = localStorage.getItem('showFilters');
    return storedShowFilters !== null ? JSON.parse(storedShowFilters) : false;
  });

  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  const fetchInProgressRef = useRef(false);

  // Refresh notifications
  const [notificationRefreshTrigger, setNotificationRefreshTrigger] = useState(0);

  // Mobile layout
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1199);

  // Update leads use effect - when the query or sort order is changed
  useEffect(() => {
    console.log("Filters changed to:", { filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus, filteredAssignedTo });
    setPage(1);
    setLastDocId(null);
    setLastPageReached(false);
    const delayDebounceFn = setTimeout(() => {
      fetchLeads({
        query: searchQuery,
        sort: sortField,
        sortOrder,
        page: 1,
        filteredYear,
        filteredMake,
        filteredModel,
        filteredOtherStatus,
        filteredLeadStatus,
        filteredAssignedTo
      });
    }, 200);
    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, sortField, sortOrder, filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus, filteredAssignedTo, filterChangeCount]);

  // Function to deduplicate leads by their id
  const deduplicateLeads = (newLeads: Lead[]): Lead[] => {
    const allLeads = [...leads, ...newLeads];
    const uniqueLeads = Array.from(new Map(allLeads.map(lead => [lead.id, lead])).values());
    return uniqueLeads;
  };

  // Main fetchLeads function
  const fetchLeads = async (params: {
    query: string,
    sort: string,
    sortOrder: string,
    page: number,
    filteredYear?: string,
    filteredMake?: string,
    filteredModel?: string,
    filteredOtherStatus?: string,
    filteredLeadStatus?: string,
    filteredAssignedTo?: string,
  }) => {
    // Update the latest query reference
    latestQueryRef.current = params.query;
    setIsLoading(true);

    try {
      const response = await axios.get<Lead[]>(`${API_PREFIX}/leads`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        params: {
          ...params,
          pageSize,
          lastId: lastDocId,
        },
      });
  
      // Check if the response matches the latest query
      if (params.query === latestQueryRef.current) {
        if (response.data.length < pageSize) {
          setLastPageReached(true);
        }
  
        if (response.data.length > 0) {
          setLastDocId(response.data[response.data.length - 1].id);
          setLeads(prevLeads => deduplicateLeads([...prevLeads, ...response.data]));
          setPage(prevPage => prevPage + 1);
        }
      } else {
        console.log(`Discarding stale response for query: ${params.query}`);
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Populate the search box with the initial query from the URL
  useEffect(() => {
    const initialQuery = getQueryParam('s');
    if (initialQuery) {
      setSearchQuery(initialQuery);
      fetchLeads({
        query: initialQuery,
        sort: sortField,
        sortOrder,
        page: 1,
      });
    }
  }, [location.search]);

  // Debounced function to fetch suggestions
  const fetchSuggestions = useRef(
    debounce(async (query: string) => {
      if (!query) {
        setSuggestions([]);
        return;
      }
      setIsFetchingSuggestions(true);
      try {
        const response = await axios.get(`${API_PREFIX}/suggestions`, {
          params: { query },
        });
        setSuggestions(response.data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setIsFetchingSuggestions(false);
      }
    }, 20)
  ).current;

  // Adjust the useEffect hook that handles scroll to prevent multiple fetches
  useEffect(() => {
    const handleScroll = () => {
      const nearBottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight - 1000;
      if (nearBottom && !isLoadingRef.current && !fetchInProgressRef.current) {
        fetchInProgressRef.current = true;
        fetchLeads({
          query: searchQuery,
          sort: sortField,
          sortOrder,
          page,
          filteredYear,
          filteredMake,
          filteredModel,
          filteredOtherStatus,
          filteredLeadStatus
        }).finally(() => {
          fetchInProgressRef.current = false;
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastPageReached, fetchLeads, searchQuery, sortField, sortOrder, filteredYear, filteredMake, filteredModel, filteredOtherStatus, filteredLeadStatus, page]);

  function isNumeric(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }

  // Set search query on text change
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const newQuery = e.target.value;
    console.log("Search input changed:", newQuery);

    // Update state immediately for responsiveness
    setSearchQuery(newQuery);
    if(newQuery.length > 1 && !isNumeric(newQuery)){
      fetchSuggestions(newQuery);
    } else {
      setSuggestions([]); // Clear suggestions for invalid queries
    }
    // Reset state before initiating a new API call
    setLeads([]);
    setPage(1);
    setLastDocId(null);
    setLastPageReached(false);

    // Debounced fetch
    // debouncedFetchLeads(newQuery);
  };

  // Handle selecting a suggestion
  const handleSuggestionClick = (suggestion: { make: string; model: string }) => {
    setSearchQuery(`${suggestion.make} ${suggestion.model}`);
    refreshData();
    setSuggestions([]);
  };

  // Handle clicking outside suggestions
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setSuggestions([]); // Clear suggestions
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle sort change
  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator

    // Protect against undefined values
    const value = event.target.value || '';
    const [field, order] = value.split('_');
    setSortField(field);
    setSortOrder(order);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    localStorage.setItem('showFilters', JSON.stringify(showFilters));
  }, [showFilters]);
  

  const handleFilterChange = ({ year = '', make = '', model = '', leadStatus = '', otherStatus = '', assignedTo = '' }: { year?: string, make?: string, model?: string, leadStatus?: string, otherStatus?: string, assignedTo?: string }) => {
      setLeads([]); // Clear existing leads
      setPage(1); // Set page to 1
      setLastDocId(null); // Reset pagination continuation
      setLastPageReached(false); // Reset end of data indicator
      setFilterChangeCount(count => count + 1); // Hacky way of forcing the useEffect to update

      setFilteredYear(year);
      setFilteredMake(make);
      setFilteredModel(model);
      setFilteredOtherStatus(otherStatus);
      setFilteredLeadStatus(leadStatus);
      setFilteredAssignedTo(assignedTo);

      console.log(`Filtered by ${year}, ${make}, ${model}, ${otherStatus}, ${leadStatus}, ${assignedTo}`);
  };

  // Refresh function
  const refreshData = () => {
    setIsLoading(true);
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator
    setFilterChangeCount(count => count + 1); // Hacky way of forcing the useEffect to update
    setNotificationRefreshTrigger(trigger => trigger + 1); // Refresh notifications (bell icon)
  };

  // Reset function

  const resetSearch = () => {
    setSearchQuery('');
    setIsLoading(true);
    setLeads([]); // Clear existing leads
    setPage(1); // Set page to 1
    setLastDocId(null); // Reset pagination continuation
    setLastPageReached(false); // Reset end of data indicator
    setFilterChangeCount(count => count + 1); // Hacky way of forcing the useEffect to update
    setNotificationRefreshTrigger(trigger => trigger + 1); // Refresh notifications (bell icon)
  } 

  // Check window width for mobile layout
  useEffect(() => {
    // Debounced resize handler
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 1199);
    }, 100);
  
    // Add the resize event listener
    window.addEventListener('resize', handleResize);
  
    // Set the initial value on mount
    handleResize();
  
    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNotificationClick = (leadId: string) => {
    setSearchQuery(leadId);
    refreshData();
  };

  return (
    <Box>
      <ContentWidth>
        <HeaderArea>
          <Box>
            <h3 style={{ color: '#282828', margin: '10px 0 0', width: '120px' }}>Dashboard</h3>
            { /* hideSensitive ? (
              <Button onClick={() => setHideSensitive(false)} style={{ height: '40px', width: '40px', }}>
                <VisibilityOnIcon style={{ fontSize: '22px' }} />
              </Button>
            ) : (
              <Button onClick={() => setHideSensitive(true)} style={{ height: '40px', width: '40px', }}>
                <VisibilityOffIcon style={{ fontSize: '22px', color: '#959595' }} />
              </Button>
            ) */}
          </Box>
          <Box>
            <AnalyticsMinimal />
          </Box>
        </HeaderArea>
        <SearchFlex>
          {searchQuery.length > 0 && (
            <SearchReset onClick={resetSearch}>Reset</SearchReset>
          )}
          <TextField
            fullWidth
            label="Search leads..."
            variant="outlined"
            value={searchQuery}
            onChange={handleSearchChange}
          >
          </TextField>
          {/* Display Suggestions */}
          {suggestions.length > 0 && (
            <div ref={suggestionsRef} style={{
                position: 'absolute',
                zIndex: 10,
                marginTop: '62px',
                borderRadius: '12px',
                background: 'white',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.22),0px 4px 5px 0px rgba(0,0,0,0.16),0px 1px 10px 0px rgba(0,0,0,0.14)',
                overflow: 'hidden',
                }}>
              <List
                sx={{
                  border: '1px solid #ccc',
                  width: ['300px', '440px'],
                  maxHeight: ['260px', '340px'],
                  overflowY: 'auto',
                }}
              >
                {suggestions.map((suggestion, index) => (
                  <StyledListItem
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    <ListItemText primary={`${suggestion.make} ${suggestion.model}`} sx={{ height: '24px', lineHeight: '24px' }} />
                  </StyledListItem>
                ))}
                {isFetchingSuggestions && (
                  <Box style={{ padding: '8px 16px', color: '#777' }}>Loading suggestions...</Box>
                )}
              </List>
            </div>
          )}
          <SortFlex>
            <FormControl variant="outlined" sx={{ minWidth: [80,100], width: isMobile ? '100%' : 'auto' }}>
              <InputLabel style={{ background: 'white', padding: '0 10px' }}>Sort By</InputLabel>
              <Select value={`${sortField}_${sortOrder}`} onChange={handleSortChange} style={{ height: '56px', lineHeight: '32px' }}>
                <MenuItem value="createdAt_desc">Date (Latest)</MenuItem>
                <MenuItem value="createdAt_asc">Date (Oldest)</MenuItem>
                {/*  ## This is commented out on the server, due to long processing times with large lead numbers ##
                  <MenuItem value="lastName_asc">Last Name (A-Z)</MenuItem>
                  <MenuItem value="lastName_desc">Last Name (Z-A)</MenuItem>
                  <MenuItem value="estimate_desc">Estimate (High-Low)</MenuItem>
                  <MenuItem value="estimate_asc">Estimate (Low-High)</MenuItem>
                  <MenuItem value="year_desc">Year (Latest)</MenuItem>
                  <MenuItem value="year_asc">Year (Oldest)</MenuItem>
                 */}
              </Select>
            </FormControl>
            <StyledDashboardButton onClick={refreshData} >
              <RefreshIcon style={{ fontSize: '22px' }} />
            </StyledDashboardButton>
            
            {user?.id && (
              <Notifications userId={user.id} onNotificationClick={handleNotificationClick} refreshTrigger={notificationRefreshTrigger} />
            )}
            {user?.id && (
              <SMSNotifications userId={user.id} onMessageClick={handleNotificationClick} refreshTrigger={notificationRefreshTrigger} />
            )}
            <StyledDashboardButton onClick={toggleFilters}>
              <TuneIcon style={{ fontSize: '22px' }} />
            </StyledDashboardButton>
          </SortFlex>
        </SearchFlex>
        {showFilters && (
          <FilterOptions onFilterChange={handleFilterChange} onResetSearchQuery={() => { setSearchQuery(''); setSortOrder('desc'); }} />
        )}
        {leads.length > 0 && (
          leads.map((lead) => (
            filteredLeadStatus === '' ? ( // Exclude dead, duplicates and deleted entries from the default view
              (lead.leadStatus !== 'Dead' && lead.leadStatus !== 'Duplicate' && lead.leadStatus !== 'Delete') && (
                <LeadDisplay key={lead.id} lead={lead} hideSensitive={hideSensitive} isMobile={isMobile} />
              )
            ) : (
              <LeadDisplay key={lead.id} lead={lead} hideSensitive={hideSensitive} isMobile={isMobile} />
            )
          ))
        )}
        {isLoading ? (
          <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
            <Typography style={{ fontSize: '16px', color: '#777' }}>
              Loading ...
            </Typography>
          </Box>
        ) : (
          (leads.length > 0 && lastPageReached === true) ? (
            <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
              <Typography style={{ fontSize: '16px', color: '#777' }}>
                End of results.
              </Typography>
            </Box>
          ) : (
            leads.length === 0 && (
              <Box style={{ display: 'flex', justifyContent: 'center', margin: '40px 0 20px' }}>
                <Typography style={{ fontSize: '16px', color: '#777' }}>
                  No leads matched your query and filter options.
                </Typography>
              </Box>
            )
          )
        )}
      </ContentWidth>
    </Box>
  );
};

export default DashboardPage;
