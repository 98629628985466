import { Box, Button, Select, Typography } from '@mui/material';
import styled from 'styled-components';



export const StyledDashboardButton = styled(Button)`
&&{
    border: 1px solid rgba(0,0,0,0.22);
    color: #888;
    &:hover{
        border: 1px solid rgba(0,0,0,0.7);
    }
    &:active{
        color: #B9E123;
    }
    @media all and (max-width: 599px){
        min-width: 54px;
    }
}
`;



