import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';

const SetPasswordPage: React.FC = () => {
  const { setupPassword } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetToken, setResetToken] = useState('');

  const handleSetupPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await setupPassword(email, password, resetToken); // Use setupPassword function from AuthContext
      navigate('/'); // Navigate to login page upon successful password setup
    } catch (error) {
      console.error('Set password error:', error);
      alert('Set password error');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh' }}
    >
      <form onSubmit={handleSetupPassword}>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Reset Token"
            type="text"
            name="resetToken"
            value={resetToken}
            onChange={(e) => setResetToken(e.target.value)}
            required
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
            Set Password
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default SetPasswordPage;
