import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, Typography, Box, Container, Paper, Grid, CircularProgress, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { API_PREFIX } from '../constants/urls';
import { ContentWidth } from '../components/ThemeComponents';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import styled from 'styled-components';

const StyledFormControlLabel = styled(FormControlLabel)`
&&{
  font-size: 16px;
}
`

const AppointmentTimes: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [dayAvailable, setDayAvailable] = useState<boolean | null>(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [blockedTimesOffice, setBlockedTimesOffice] = useState<number[]>([]);
  const [blockedTimesHome, setBlockedTimesHome] = useState<number[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [updating, setUpdating] = useState<boolean>(false);

  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const response = await axios.get(`${API_PREFIX}/available-dates`);
        setAvailableDates(response.data.availableDates);
      } catch (error) {
        console.error('Error fetching available dates:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAvailableDates();
  }, []);

  const handleDateChange = async (newValue: Dayjs | null) => {
    if (newValue !== null) {
      setSelectedDate(newValue);
      const formattedDate = newValue.format('YYYY-MM-DD');
      setDayAvailable(availableDates.includes(formattedDate));
      try {
        const response = await axios.get(`${API_PREFIX}/available-times/${formattedDate}`);
        const { blockedTimesOffice, blockedTimesHome } = response.data;
        setBlockedTimesOffice(blockedTimesOffice || []);
        setBlockedTimesHome(blockedTimesHome || []);
      } catch (error) {
        console.error('Error fetching blocked times:', error);
      }
    }
  };

  const handleDayAvailabilityChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    setUpdating(true);
    try {
      await axios.post(
        `${API_PREFIX}/update-day-availability`, 
        { date: formattedDate, available: checked }, 
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
      });
      setDayAvailable(checked);
      setAvailableDates(prev => checked ? [...prev, formattedDate] : prev.filter(date => date !== formattedDate));
    } catch (error) {
      console.error('Error updating day availability:', error);
    } finally {
      setUpdating(false);
    }
  };

  const handleBlockedTimesChange = async (time: number, type: 'office' | 'home') => {
    let newBlockedTimesOffice = [...blockedTimesOffice];
    let newBlockedTimesHome = [...blockedTimesHome];

    if (type === 'office') {
      if (blockedTimesOffice.includes(time)) {
        newBlockedTimesOffice = newBlockedTimesOffice.filter(t => t !== time);
        
      } else {
        newBlockedTimesOffice.push(time);
        if(!blockedTimesHome.includes(time)) {
          newBlockedTimesHome.push(time);
        }
      }
      setBlockedTimesOffice(newBlockedTimesOffice);
      setBlockedTimesHome(newBlockedTimesHome);
    } else { // home
      if (blockedTimesHome.includes(time)) {
        newBlockedTimesHome = newBlockedTimesHome.filter(t => t !== time);
      } else {
        newBlockedTimesHome.push(time);
      }
      setBlockedTimesHome(newBlockedTimesHome);
    }

    const formattedDate = selectedDate.format('YYYY-MM-DD');
    setUpdating(true);
    try {
      await axios.post(
        `${API_PREFIX}/update-blocked-times`, 
        {
          date: formattedDate,
          blockedTimesOffice: newBlockedTimesOffice,
          blockedTimesHome: newBlockedTimesHome
        }, 
        { 
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` 
        },
      });
    } catch (error) {
      console.error('Error updating blocked times:', error);
    } finally {
      setUpdating(false);
    }
  };

  const timeSlots = Array.from({ length: 18 }, (_, i) => i / 2 + 8.5);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ContentWidth>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Container style={{ minHeight: 'Calc(100vh - 94px)' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }}>
            <h3 style={{ color: '#282828', margin: '10px 0 0' }}>Opening Hours</h3>
            <Button
              href="/"
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{ marginTop: '4px', marginLeft: '10px', }}
          >
              Back
          </Button>
          </div>
          <Paper elevation={3} sx={{ p: [0, '30px 30px'], mt: 4, mb: 20 }}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={7}>
                <Box className="Mui-date-calendar-wrap">
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedDate}
                    onChange={handleDateChange}
                    disablePast
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={5} padding={2}>
                {dayAvailable !== null && (
                  <FormControlLabel
                    control={<Checkbox checked={dayAvailable} onChange={handleDayAvailabilityChange} disabled={updating} />}
                    label="Day Available"
                  />
                )}
                <Typography margin="20px 0 10px">Available Appointment Times:</Typography>
                <Grid container spacing={1}>
                  {timeSlots.map((time, index) => (
                    <Grid item xs={4} key={index} sx={{ display: 'flex', mb: '10px', justifyContent: 'center', alignItems: 'center' }}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!blockedTimesOffice.includes(time)}
                              onChange={() => handleBlockedTimesChange(time, 'office')}
                              disabled={updating || !dayAvailable}
                              sx={{ padding: '0', margin: '0 0 0 8px' }}
                            />
                          }
                          label=""
                        />
                      </Box>
                      <Box>
                        <StyledFormControlLabel
                          control={
                            <Checkbox
                              checked={!blockedTimesHome.includes(time)}
                              onChange={() => handleBlockedTimesChange(time, 'home')}
                              sx={{ padding: '0', margin: '0 0 0 -6px', svg: { color: dayAvailable && !blockedTimesHome.includes(time) ? '#6D9DFF' : 'inherit' }, verticalAlign: 'middle',
                              '& .MuiFormControlLabel-label' : {
                                margin: '2px 0 0'
                              },
                              fontSize: '10px'
                              }}
                              disabled={updating || !dayAvailable}
                            />
                          }
                          label={dayjs().startOf('day').add(time, 'hour').format('hh:mm')}
                          labelPlacement="end"
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ mt: 2, p: 2, border: '1px solid #EAEAEA', borderRadius: '12px' }}>
                  <Typography>Legend:</Typography>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={true} />
                        <Typography style={{ fontSize: '16px' }}>In Office</Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={true} sx={{ color: '#6D9DFF', svg: { color: '#6D9DFF' } }} />
                        <Typography style={{ fontSize: '16px' }}>Out of Office</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 2, p: 2, border: '1px solid #EAEAEA', borderRadius: '12px' }}>
                  <Typography style={{ fontSize: '16px', color: '#444' }}>Constraints:</Typography>
                  <Typography style={{ fontSize: '14px', color: '#444' }}>Out of office appointments are restricted in the next 24 hours and before 11am Monday (if current time is after midday on Saturday).</Typography>
                </Box>
                {updating && <CircularProgress size={24} />}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </LocalizationProvider>
    </ContentWidth>
  );
};

export default AppointmentTimes;