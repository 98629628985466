import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { API_PREFIX } from '../../constants/urls';

const LightboxOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1200;
  user-select: none;
`;
const LightboxOverlayInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 90vh;
  width: 100%; // Ensure it spans the full width of the viewport
  max-width: 100%;
  margin-top: 50vh;
  transform: translate(0, -50%);

`;

const NavigationArea = styled.div<{ direction: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  bottom: 0;
  ${({ direction }) => (direction === 'left' ? 'left: 0;' : 'right: 0;')}
  width: Calc(30% - 40px);
  min-height: 80%;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 20px;
  z-index: 10;
  background-color: transparent;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(0, 0, 0, 0); // Light transparent grey/black on hover - removed currently
  }
`;

const LightboxImage = styled.img`
  max-width: 94%;
  max-height: 80vh;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
`;

  
const ImageNavigationButton = styled.div<{ direction: 'left' | 'right' }>`
  display: block;
  color: white;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  @media all and (min-width: 1200px){
    position: absolute;

  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  display: block;
  color: white;
  z-index: 1210;
  cursor: pointer;
`;

const ImageIndicator = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
`;

const ImageCounter = styled.div`
  display: block;
  width: 90px;
`

const DeleteButton = styled(IconButton)`
  position: relative;
  top: -3px;
  right: 40px;
  width: 34px;
  height: 34px;
  cursor: pointer;
`;

const Lightbox = ({ isOpen, leadId, images, initialIndex, onClose, onImageDelete }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(initialIndex);
    }
  }, [initialIndex, isOpen]);

  const handleNext = (e) => {
    e.stopPropagation(); // Stop event from propagating to the LightboxOverlay
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  
  const handlePrevious = (e) => {
    e.stopPropagation(); // Stop event from propagating to the LightboxOverlay
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const openDeleteDialog = () => {
    setDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    const authToken = localStorage.getItem('token');
    try {
      await axios.post(`${API_PREFIX}/admin-delete-image`, {
        id: leadId,
        imageUrl: images[currentIndex],
      }, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      // Assuming `onImageDelete` is a function passed from the parent that updates the state of images
      onImageDelete(images[currentIndex]);
      console.log("Image deleted successfully");
      closeDeleteDialog();
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
    {isOpen && ( 
      <LightboxOverlay onClick={onClose} style={{ zIndex: '1200' }}>
        <CloseButton style={{ position: 'absolute', top: '30px', right: '38px' }} onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <LightboxOverlayInner>
          {images.length > 1 && (
            <>
              <NavigationArea direction="left" onClick={(e) => { e.stopPropagation(); handlePrevious(e); }}>
                <ArrowBackIosIcon style={{ color: 'white', zIndex: 11 }} />
              </NavigationArea>
              <NavigationArea direction="right" onClick={(e) => { e.stopPropagation(); handleNext(e); }} style={{ justifyContent: 'flex-end'}}>
                <ArrowForwardIosIcon style={{ color: 'white', zIndex: 11 }} />
              </NavigationArea>
            </>
          )}
          <LightboxImage src={images[currentIndex]} alt="" onClick={(e) => e.stopPropagation()} />
        </LightboxOverlayInner>
        <ImageIndicator>
          <DeleteButton onClick={(e) => {
                  e.stopPropagation(); // Prevents the click from reaching the overlay
                  openDeleteDialog();
                }}>
            <DeleteIcon style={{ width: '20px', color: '#b93333' }} />
          </DeleteButton>
          <ImageCounter>
            {`${currentIndex + 1} / ${images.length}`}
          </ImageCounter>
        </ImageIndicator>
      </LightboxOverlay>
      )}
      {/* Confirmation Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete Image"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this image permanently?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Lightbox;