import React from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Crop169Icon from '@mui/icons-material/Crop169';
import styled from 'styled-components';
import { Box } from '@mui/material';

const ConditionBox = styled(Box)`
  display: block;
  width: 20px;
  height: 20px;
  text-align: center;
  color: #777;
`

interface ConditionSentimentProps {
  condition: string;
}

const ConditionSentiment: React.FC<ConditionSentimentProps> = ({ condition }) => {
  const getSentimentIcon = () => {
    
    const positiveKeywords = ['good', 'great', 'new', 'mint', 'excellent', 'immaculate', 'spotless', 'pristine', 'perfect', 'superb', 'like new', 'flawless', 
      'unblemished', 'polished', 'shiny', 'gleaming', 'well-kept', 'maintained', 'durable', 'reliable', 'high-quality', 'top-notch', 'first-rate', 
      'premium', 'luxurious', 'superior', 'exceptional', 'outstanding', 'remarkable', 'fantastic', 'splendid'];

    const negativeKeywords = ['bad', 'wear', 'tear', 'damaged', 'worn', 'old', 'used', 'scratched', 'dented', 'rusty', 'corroded', 'tarnished', 
      'blemished', 'stained', 'faded', 'cracked', 'broken', 'faulty', 'defective', 'problems', 'problematic', 'poor', 'inferior', 'substandard', 'compromised', 
      'malfunctioning', 'unreliable', 'degraded', 'dilapidated', 'neglected', 'abused'];

      const conditionWords = condition.toLowerCase().split(/\s+/);

      const positiveCount = conditionWords.reduce((count, word) => count + (positiveKeywords.includes(word) ? 1 : 0), 0);
      const negativeCount = conditionWords.reduce((count, word) => count + (negativeKeywords.includes(word) ? 1 : 0), 0);
  

    if (positiveCount > negativeCount) {
      return (
        <ConditionBox>
          <ThumbUpOffAltIcon style={{ margin: '-3px 0 2px', width: '16px' }} />
        </ConditionBox>
      );
    } else if (negativeCount > positiveCount){
      return (
        <ConditionBox>
          <ThumbDownOffAltIcon style={{ margin: '-3px 0 2px', width: '16px' }} />
        </ConditionBox>
        );
    } else {
      return (
        <ConditionBox>
          <ThumbUpOffAltIcon style={{ margin: '-3px 0 2px', width: '16px' }} />
        </ConditionBox>
        );
    }
  };

  return getSentimentIcon();
};

export default ConditionSentiment;