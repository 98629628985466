import React, { useState } from 'react';
import { Lead } from '../../types/LeadTypes';
import { Box, Button, TextField, Typography, Grid, MenuItem, IconButton, Paper } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { styled, Theme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';

interface EditLeadProps {
  lead: Lead;
  onCancel: () => void;
  onUpdate: (updatedLead: Lead) => void; // New callback prop to handle lead update
}

const API_PREFIX = process.env.REACT_APP_SERVER_API_URL;

const StyledTextField = styled(TextField)`
  width: 100%;
  height: 2.4rem;
  margin-bottom: 8px;
  border-radius: 12px;
  input{
    height: 1.2rem;
    line-height: 1rem;
    border: 0 none;
    outline: 0 none;
    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #333 inset !important;
      -webkit-text-fill-color: white !important;
    }
  }
`;

const StyledFormControl = styled(FormControl)(({ theme }: { theme: Theme }) => ({
  marginTop: '16px',
  marginBottom: '0',
  padding: '0',
  width: '100%',
  height: '3.2rem',
  lineHeight: '3.2rem',
}));

const StyledInputLabel = styled(InputLabel)(({ theme }: { theme: Theme }) => ({
  background: 'white',
  top: '-4px',
  lineHeight: '1.6rem',
  '&.Mui-focused': {
    color: 'grey',
  },
  '&.MuiInputLabel-shrink': {
    top: '-6px',
    padding: '0 4px',
    color: 'grey',
    transform: 'translate(10px, -3px) scale(0.75)',
    background: 'rgb(255,255,255)',
  },
}));


const StyledSelect = styled(Select)<{ hasValue?: boolean } & SelectProps>(({ theme, hasValue }) => ({
  maxWidth: '100%',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: '2rem',
  height: '3rem',
  padding: '0',
  color: 'grey',
  border: '0 none',
  boxShadow: 'none',
  outline: '1px solid #CACACA',
  transition: 'height 400ms, line-height 400ms',
  minHeight: '0 !important',
  '& .MuiSelect-icon': {
    color: 'grey',
  },
  '&:hover': {
    outline: '1px solid #282828',
  },
  '&.Mui-focused': {
    background: 'transparent',
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0 none',
  },
  '& .MuiOutlinedInput-root::before': {
    content: 'none',
  },
  '& .MuiOutlinedInput-root::after': {
    content: 'none',
  },
  '& .MuiSelect-root': {
    backdropFilter: 'none',
  },
}));

const StyledLocalizationProvider = styled(LocalizationProvider)(({ theme }) => ({
  width: '100%',
  padding: '2px',
  borderRadius: '12px',
  background: 'transparent',
  color: 'grey',
  height: 'rem',
  lineHeight: '3rem',
  border: '0 none !important', //  : '2px solid #B9E123', // Green border if no date
  transition: 'border 200ms ease-in-out',
  '&:hover': {
    border: '2px solid #B9E123', // Always show green border on hover
  },
  '& .MuiInputBase-root': {
    color: 'grey', // Ensures text color is grey
    height: '2rem'
  },
}));

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 36px;
  height: 36px;
  @media all and (max-width: 400px){
    bottom: -40px;
  }
`

const EditLead: React.FC<EditLeadProps> = ({ lead, onCancel, onUpdate }) => {
  const [editedLead, setEditedLead] = useState<Lead>(lead);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(lead.appointmentDate ? dayjs(lead.appointmentDate) : null);
  const [selectedTime, setSelectedTime] = useState(lead.appointmentTime || '');

  const handleInputChange = (field: keyof Lead, value: any) => {
    if (field === 'year' || field === 'kilometers' || field === 'priceTarget' || field === 'estimatedPLow' || field === 'estimatedPHigh') {
      // Ensure only numeric values
      if (/^\d*$/.test(value)) {
        setEditedLead({ ...editedLead, [field]: value });
      }
    } else if (field === 'rego') {
      // Transform text to uppercase
      setEditedLead({ ...editedLead, [field]: value.toUpperCase() });
    } else {
      setEditedLead({ ...editedLead, [field]: value });
    }
  };

  const handlePersonalInfoChange = (field: keyof Lead['personalInfo'], value: any) => {
    setEditedLead({ ...editedLead, personalInfo: { ...editedLead.personalInfo, [field]: value } });
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    setSelectedDate(newValue);
    if (newValue) {
      handleInputChange('appointmentDate', newValue.format('YYYY-MM-DD'));
    }
  };

  const handleTimeChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    const value = event.target.value as string;
    setSelectedTime(value);
    handleInputChange('appointmentTime', value);
  };
  

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_PREFIX}/update-lead`, {
        leadId: lead.id,
        updatedLead: editedLead,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      onUpdate(editedLead); // Call the onUpdate callback with the updated lead
    } catch (error) {
      console.error('Error saving lead:', error);
    }
  };

  return (
    <Paper elevation={3} style={{ padding: '2px 20px', marginBottom: '12px' }}>
      <Box style={{ position: 'relative' }}>
        <StyledIconButton onClick={onCancel}><CloseIcon style={{ color: 'grey' }} /></StyledIconButton>
        <Typography variant="h6" style={{ fontWeight: 'bold', margin: '20px 0 10px'}}>Editing Lead - {lead.year} {lead.make} {lead.model} [{lead.personalInfo.firstName} {lead.personalInfo.lastName}]</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} mt={[0, 2]}>
          <Typography variant="subtitle1" fontWeight="bold" color="#282828">Personal Information</Typography>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="First Name"
                value={editedLead.personalInfo.firstName}
                onChange={(e) => handlePersonalInfoChange('firstName', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Last Name"
                value={editedLead.personalInfo.lastName}
                onChange={(e) => handlePersonalInfoChange('lastName', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <StyledTextField
            label="Email"
            value={editedLead.personalInfo.email}
            onChange={(e) => handlePersonalInfoChange('email', e.target.value)}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Phone"
                value={editedLead.personalInfo.phone}
                onChange={(e) => handlePersonalInfoChange('phone', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Postcode"
                value={editedLead.personalInfo.postcode}
                onChange={(e) => handlePersonalInfoChange('postcode', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <StyledTextField
            label="Full Address (# Street, Suburb)"
            value={editedLead.personalInfo.address}
            onChange={(e) => handlePersonalInfoChange('address', e.target.value)}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={6} mt={[1, 2]}>
          <Typography variant="subtitle1" fontWeight="bold" color="#282828">Vehicle Information</Typography>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                label="Year"
                value={editedLead.year}
                onChange={(e) => handleInputChange('year', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                label="Make"
                value={editedLead.make}
                onChange={(e) => handleInputChange('make', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                label="Model"
                value={editedLead.model}
                onChange={(e) => handleInputChange('model', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Shape"
                value={editedLead.shape}
                onChange={(e) => handleInputChange('shape', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Badge"
                value={editedLead.badge}
                onChange={(e) => handleInputChange('badge', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Engine"
                value={editedLead.engine}
                onChange={(e) => handleInputChange('engine', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Transmission"
                value={editedLead.transmission}
                onChange={(e) => handleInputChange('transmission', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Kilometers"
                value={editedLead.kilometers}
                onChange={(e) => handleInputChange('kilometers', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Rego"
                value={editedLead.rego}
                onChange={(e) => handleInputChange('rego', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} mt={[1, 2]}>
          <Typography variant="subtitle1" fontWeight="bold" color="#282828">Further Information</Typography>
          <StyledTextField
            label="Vehicle Condition"
            value={editedLead.condition}
            onChange={(e) => handleInputChange('condition', e.target.value)}
            fullWidth
            margin="normal"
          />
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={4}>
            <StyledFormControl>
              <StyledInputLabel id="finance-label">Finance</StyledInputLabel>
              <StyledSelect
                labelId="finance-label"
                id="finance-select"
                value={editedLead.finance || ''}
                onChange={(e) => handleInputChange('finance', e.target.value)}
                fullWidth
              >
                <MenuItem value=""><i style={{ color: 'grey' }}>Unknown</i></MenuItem>
                <MenuItem value="Yes">Under Finance</MenuItem>
                <MenuItem value="No">No Finance</MenuItem>
              </StyledSelect>
            </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                label="Urgency"
                value={editedLead.urgency}
                onChange={(e) => handleInputChange('urgency', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <StyledTextField
                label="Price Target"
                value={editedLead.priceTarget}
                onChange={(e) => handleInputChange('priceTarget', e.target.value)}
                fullWidth
                margin="normal"
              />
              </Grid>
          </Grid>
          <Grid container spacing={[0, 1]}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Estimated Price Low"
                value={editedLead.estimatedPLow}
                onChange={(e) => handleInputChange('estimatedPLow', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                label="Estimated Price High"
                value={editedLead.estimatedPHigh}
                onChange={(e) => handleInputChange('estimatedPHigh', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} mt={[1, 2]}>
          <Typography variant="subtitle1" fontWeight="bold" color="#282828">Appointment Information</Typography>
          <StyledFormControl>
            <StyledInputLabel id="location-label">Appointment Location</StyledInputLabel>
            <StyledSelect
              labelId="location-label"
              id="location-select"
              value={editedLead.appointmentLocation || ''}
              onChange={(e) => handleInputChange('appointmentLocation', e.target.value)}
              fullWidth
            > 
              <MenuItem value=""><i style={{ color: 'grey' }}>No Appointment</i></MenuItem>
              <MenuItem value="Car Buyers Online">Car Buyers Online (Eagle Farm)</MenuItem>
              <MenuItem value="My Address">My Address (Enter in the "Full Address" field above)</MenuItem>
            </StyledSelect>
          </StyledFormControl>
          <StyledLocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className="desktop-date-picker" style={{ outline: selectedDate ? '1px solid #3C3C3C' : '2px solid #B9E123' }}>
              <DesktopDatePicker
                value={selectedDate ?? null}
                onChange={handleDateChange}
                format="DD/MM/YY"
                disablePast
              />
            </Box>
            <Box className="mobile-date-picker" style={{ outline: selectedDate ? '1px solid #3C3C3C' : '2px solid #B9E123' }}>
              <MobileDatePicker
                value={selectedDate ?? null}
                onChange={handleDateChange}
                format="DD/MM/YY"
                disablePast
              />
            </Box>
          </StyledLocalizationProvider>
          <StyledFormControl>
            <StyledInputLabel id="time-label">Select a Time</StyledInputLabel>
            <StyledSelect
              labelId="time-label"
              id="time-select"
              value={selectedTime}
              onChange={handleTimeChange}
              fullWidth
            >
              <MenuItem value=""><i style={{color: 'grey'}}>Unset Time</i></MenuItem>
              <MenuItem value="07:30 AM">07:30 AM</MenuItem>
              <MenuItem value="08:00 AM">08:00 AM</MenuItem>
              <MenuItem value="08:30 AM">08:30 AM</MenuItem>
              <MenuItem value="09:00 AM">09:00 AM</MenuItem>
              <MenuItem value="09:30 AM">09:30 AM</MenuItem>
              <MenuItem value="10:00 AM">10:00 AM</MenuItem>
              <MenuItem value="10:30 AM">10:30 AM</MenuItem>
              <MenuItem value="11:00 AM">11:00 AM</MenuItem>
              <MenuItem value="11:30 AM">11:30 AM</MenuItem>
              <MenuItem value="12:00 PM">12:00 PM</MenuItem>
              <MenuItem value="12:30 PM">12:30 PM</MenuItem>
              <MenuItem value="01:00 PM">01:00 PM</MenuItem>
              <MenuItem value="01:30 PM">01:30 PM</MenuItem>
              <MenuItem value="02:00 PM">02:00 PM</MenuItem>
              <MenuItem value="02:30 PM">02:30 PM</MenuItem>
              <MenuItem value="03:00 PM">03:00 PM</MenuItem>
              <MenuItem value="03:30 PM">03:30 PM</MenuItem>
              <MenuItem value="04:00 PM">04:00 PM</MenuItem>
              <MenuItem value="04:30 PM">04:30 PM</MenuItem>
              <MenuItem value="05:00 PM">05:00 PM</MenuItem>
              <MenuItem value="05:30 PM">05:30 PM</MenuItem>
              <MenuItem value="06:00 PM">06:00 PM</MenuItem>
            </StyledSelect>
          </StyledFormControl>
        </Grid>
      </Grid>

      <Box mt={2} mb={2} textAlign="right">
        <Button onClick={handleSave} variant="contained" color="primary">Update</Button>
        <Button onClick={onCancel} variant="outlined" color="secondary" style={{ marginLeft: '10px' }}>Cancel</Button>
      </Box>
    </Paper>
  );
};

export default EditLead;
