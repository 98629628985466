import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import SITE_METADATA from '../config/SiteMetadata';
import { Helmet } from 'react-helmet-async';
import { TextField, Button, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';

type SiteMetadataType = typeof SITE_METADATA;
type SiteMetadataWithIndex = SiteMetadataType & { [key: string]: { title: string; description: string } };

const LoginPage: React.FC = () => {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const metadata = (SITE_METADATA as SiteMetadataWithIndex)[location.pathname] || {};

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await login(email, password); // Use login function from AuthContext
      localStorage.setItem('email', email); // Store email in localStorage on successful login
      navigate('/dashboard'); // Navigate upon successful login
    } catch (error) {
      console.error('Login error:', error);
      alert('Login error');
    }
  };

  useEffect(() => {
    // Any side effects to run on component mount or when dependencies change
  }, [isAuthenticated]); // Dependency array to re-run the effect if isAuthenticated changes

  // Early return for redirection should come after all hooks
  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <>
      <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
      </Helmet>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: '100vh' }}
      >
        <form onSubmit={handleLogin}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              label="Email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Password"
              type="password"
              name="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
              Login
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default LoginPage;