import { Box, Typography } from '@mui/material';
import { styled } from 'styled-components';

export const ContentWidth = styled(Box)`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 15px;
  min-height: Calc(100vh - 64px);
  box-sizing: border-box;
  overflow: hidden;
`;

export const Flex = styled(Box)`
  display: flex;
`
