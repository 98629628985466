import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import styled from 'styled-components';

const ExpandableTextBox = styled(Box)`
  display: flex; 
  flex-direction: row;
  position: relative;
  width: Calc(100% - 20px);
`;

const HiddenTextSizer = styled('span')`
  visibility: hidden;
  white-space: nowrap;
  position: absolute;
  height: auto;
  width: auto;
`;

export const ExpandableText: React.FC<{ text: string }> = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const checkForOverflow = () => {
      if (textRef.current && containerRef.current) {
        const textWidth = textRef.current.offsetWidth;
        const containerWidth = containerRef.current.offsetWidth;
        setShowExpandButton(textWidth > containerWidth * 2);
      }
    };

    // Ensure measurements are taken after layout and paint
    window.requestAnimationFrame(checkForOverflow);
    
    // Optional: Re-check on window resize if dynamic adjustment is desired
    window.addEventListener('resize', checkForOverflow);
    return () => window.removeEventListener('resize', checkForOverflow);
  }, [text]); // Re-run when text changes

  return (
    <ExpandableTextBox ref={containerRef}>
      <Typography 
        variant="body2" 
        component="p" 
        style={{ 
          width: 'Calc(100% - 20px)', 
          lineHeight: '18px', 
          fontSize: '13px', 
          padding: '2px 0', 
          color: '#777', 
          overflow: 'hidden', 
          maxHeight: isExpanded ? 'none' : '36px', 
          whiteSpace: isExpanded ? 'normal' : 'wrap', 
          textOverflow: 'ellipsis', 
        }}
        onClick={() => setIsExpanded(!isExpanded)}
        >
        {text}
      </Typography>
      {showExpandButton && (
        <IconButton onClick={() => setIsExpanded(!isExpanded)} style={{ margin: '18px 0 0', padding: '0', width: '20px', height: '20px', alignSelf: 'flex-start' }}>
          {isExpanded ? <ExpandLessIcon style={{ color: '#777', margin: '-1px 0 1px' }} /> : <ExpandMoreIcon style={{ color: '#777', margin: '0' }} />}
        </IconButton>
      )}
      {/* Invisible text for measurement */}
      <HiddenTextSizer ref={textRef}>{text}</HiddenTextSizer>
    </ExpandableTextBox>
  );
};