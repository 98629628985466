import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    border: '0 none',
    padding: '8px 24px',
    fontSize: '18px',
    fontWeight: '700',
    borderRadius: '12px',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

export default StyledButton;