

export const baseColors = {
  primary: {
      main: '#B9E123',
      light: '#D7FF40',
      dark: '#789313',
      text: "#FFFFFF",
      contrastText: '#282828'
  },
  secondary: {
    main: '#282828',
    light: '#3C3C3C',
    dark: '#191919',
    text: "#FFFFFF",
    contrastText: '#FFFFFF'
  },
  
};