import { createTheme } from '@mui/material';
import { baseColors } from './colors';
import typography from './typography';
import components from './components';

const lightTheme = createTheme({
  palette: baseColors,
  typography: typography,
  components: components,
  shape: {
    borderRadius: 12, 
  },
});

export default lightTheme;
